<template>
  <b-tabs>
    <b-tab :active="tabActive === 0" :title="$t('Personal Info')">
      <b-card>
        <validation-observer ref="infoRules" tag="form">
          <b-row>
            <b-col class="mb-2" cols="12">
              <h5 class="mb-0">
                {{ $t('Personal Info') }}
              </h5>
              <small class="text-muted">{{ $t('Enter your personal info.') }}</small>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('First Name')" label-for="first-name">
                <validation-provider #default="{errors}" name="First Name" rules="required">
                  <b-form-input
                    id="first-name"
                    v-model="first_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="John"
                  />
                  <small class="text-danger">
                    {{ errors.length > 0 ? `${$t('The')} ${$t('First Name')} ${$t('field is required')}` : '' }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('Last Name')" label-for="last-name">
                <validation-provider #default="{errors}" name="Last Name" rules="required">
                  <b-form-input
                    id="last-name"
                    v-model="last_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Doe"
                  />
                  <small class="text-danger">
                    {{ errors.length > 0 ? `${$t('The')} ${$t('Last Name')} ${$t('field is required')}` : '' }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{errors}" name="Country" rules="required">
                <b-form-group :label="$t('Country')" :state="errors.length > 0 ? false : null" label-for="country">
                  <v-select
                    id="country"
                    v-model="selectedCountry"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="countryName"
                    :selectable="option => !option.value.includes('select_value')"
                    label="text"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors.length > 0 ? `${$t('The')} ${$t('Country')} ${$t('field is required')}` : '' }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{errors}" name="Language" rules="required">
                <b-form-group :label="$t('Language')" :state="errors.length > 0 ? false : null" label-for="language">
                  <v-select
                    id="language"
                    v-model="selectedLanguage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="languageName"
                    :selectable="option => !option.value.includes('nothing_selected')"
                    label="text"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors.length > 0 ? `${$t('The')} ${$t('Language')} ${$t('field is required')}` : '' }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
        <div class="d-flex justify-content-end mt-1">
          <b-button class="d-flex align-items-center rounded-lg" variant="primary" @click="validationFormInfo">
            {{ $t('Next') }}
            <feather-icon :icon="'ChevronRightIcon'" class="ml-25 font-medium-1" />
          </b-button>
        </div>
      </b-card>
    </b-tab>
    <b-tab :active="tabActive === 1" :disabled="tabActive < 1" :title="$t('Address')">
      <b-card>
        <validation-observer ref="addressRules" tag="form">
          <b-row>
            <b-col class="mb-2" cols="12">
              <h5 class="mb-0">
                {{ $t('Address') }}
              </h5>
              <small class="text-muted">{{ $t('Enter your address.') }}</small>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('Address')" label-for="address">
                <validation-provider #default="{errors}" name="Address" rules="required">
                  <b-form-input
                    id="address"
                    v-model="address"
                    :state="errors.length > 0 ? false : null"
                    placeholder="98 Borough bridge Road, Birmingham"
                  />
                  <small class="text-danger">
                    {{ errors.length > 0 ? `${$t('The')} ${$t('Address')} ${$t('field is required')}` : '' }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('Landmark')" label-for="landmark">
                <validation-provider #default="{errors}" name="Landmark" rules="required">
                  <b-form-input
                    id="landmark"
                    v-model="landMark"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Borough bridge"
                  />
                  <small class="text-danger">
                    {{ errors.length > 0 ? `${$t('The')} ${$t('Landmark')} ${$t('field is required')}` : '' }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('Pincode')" label-for="pincode">
                <validation-provider #default="{errors}" name="Pincode" rules="required">
                  <b-form-input
                    id="pincode"
                    v-model="pincode"
                    :state="errors.length > 0 ? false : null"
                    placeholder="658921"
                    type="number"
                  />
                  <small class="text-danger">
                    {{ errors.length > 0 ? `${$t('The')} ${$t('Pincode')} ${$t('field is required')}` : '' }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('City')" label-for="city">
                <validation-provider #default="{errors}" name="City" rules="required">
                  <b-form-input
                    id="city"
                    v-model="city"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Birmingham"
                  />
                  <small class="text-danger">
                    {{ errors.length > 0 ? `${$t('The')} ${$t('City')} ${$t('field is required')}` : '' }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <div class="d-flex justify-content-between mt-1">
          <b-button class="d-flex align-items-center rounded-lg" variant="primary" @click="tabActive--">
            <feather-icon :icon="'ChevronLeftIcon'" class="mr-25 font-medium-1" />
            {{ $t('Previous') }}
          </b-button>
          <b-button class="d-flex align-items-center rounded-lg" variant="primary" @click="validationFormAddress">
            {{ $t('Next') }}
            <feather-icon :icon="'ChevronRightIcon'" class="ml-25 font-medium-1" />
          </b-button>
        </div>
      </b-card>
    </b-tab>
    <b-tab :active="tabActive === 2" :disabled="tabActive < 2" :title="$t('Social Links')">
      <b-card>
        <validation-observer ref="socialRules" tag="form">
          <b-row>
            <b-col class="mb-2" cols="12">
              <h5 class="mb-0">
                {{ $t('Social Links') }}
              </h5>
              <small class="text-muted">{{ $t('Enter your social links') }}</small>
            </b-col>
            <b-col md="6">
              <b-form-group label="Twitter" label-for="twitter">
                <validation-provider #default="{errors}" name="Twitter" rules="required|url">
                  <b-form-input
                    id="twitter"
                    v-model="twitterUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://twitter.com/abc"
                  />
                  <small class="text-danger">
                    {{ errors.length > 0 ? `${$t('URL is invalid')}` : '' }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Facebook" label-for="facebook">
                <validation-provider #default="{errors}" name="Facebook" rules="required|url">
                  <b-form-input
                    id="facebook"
                    v-model="facebookUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://facebook.com/abc"
                  />
                  <small class="text-danger">
                    {{ errors.length > 0 ? `${$t('URL is invalid')}` : '' }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Google+" label-for="google-plus">
                <validation-provider #default="{errors}" name="Google+" rules="required|url">
                  <b-form-input
                    id="google-plus"
                    v-model="googleUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://plus.google.com/abc"
                  />
                  <small class="text-danger">
                    {{ errors.length > 0 ? `${$t('URL is invalid')}` : '' }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="LinkedIn" label-for="linked-in">
                <validation-provider #default="{errors}" name="LinkedIn" rules="required|url">
                  <b-form-input
                    id="linked-in"
                    v-model="linkedinUrl"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://linkedin.com/abc"
                  />
                  <small class="text-danger">
                    {{ errors.length > 0 ? `${$t('URL is invalid')}` : '' }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <div class="d-flex justify-content-between mt-1">
          <b-button class="d-flex align-items-center rounded-lg" variant="primary" @click="tabActive--">
            <feather-icon :icon="'ChevronLeftIcon'" class="mr-25 font-medium-1" />
            {{ $t('Previous') }}
          </b-button>
          <b-button class="d-flex align-items-center rounded-lg" variant="primary" @click="validationFormSocial">
            {{ $t('Complete') }}
          </b-button>
        </div>
      </b-card>
    </b-tab>
  </b-tabs>
  <!--  <b-row class="d-flex justify-content-center">-->
  <!--    <b-col lg="4">-->
  <!--      <b-card>-->
  <!--        <div class="mb-2">-->
  <!--          <h5 class="mb-0">-->
  <!--            {{ $t('Personal Info') }}-->
  <!--          </h5>-->
  <!--          <small class="text-muted">{{ $t('Enter your personal info.') }}</small>-->
  <!--        </div>-->
  <!--        <validation-observer-->
  <!--          ref="infoRules"-->
  <!--          tag="form"-->
  <!--        >-->
  <!--          <b-form-group-->
  <!--            :label="$t('First Name')"-->
  <!--            label-for="first-name"-->
  <!--          >-->
  <!--            <validation-provider-->
  <!--              #default="{ errors }"-->
  <!--              name="First Name"-->
  <!--              rules="required"-->
  <!--            >-->
  <!--              <b-form-input-->
  <!--                id="first-name"-->
  <!--                v-model="first_name"-->
  <!--                :placeholder="$t('John')"-->
  <!--                :state="errors.length > 0 ? false:null"-->
  <!--                class="rounded-nrm"-->
  <!--              />-->
  <!--              <small class="text-danger">-->
  <!--                {{-->
  <!--                  errors.length > 0-->
  <!--                    ? `${$t('The')} ${$t('First Name')} ${$t('field is required')}`-->
  <!--                    : ''-->
  <!--                }}-->
  <!--              </small>-->
  <!--            </validation-provider>-->
  <!--          </b-form-group>-->
  <!--          <b-form-group-->
  <!--            :label="$t('Last Name')"-->
  <!--            label-for="last-name"-->
  <!--          >-->
  <!--            <validation-provider-->
  <!--              #default="{ errors }"-->
  <!--              name="Last Name"-->
  <!--              rules="required"-->
  <!--            >-->
  <!--              <b-form-input-->
  <!--                id="last-name"-->
  <!--                v-model="last_name"-->
  <!--                :placeholder="$t('Doe')"-->
  <!--                :state="errors.length > 0 ? false:null"-->
  <!--                class="rounded-nrm"-->
  <!--              />-->
  <!--              <small class="text-danger">-->
  <!--                {{-->
  <!--                  errors.length > 0-->
  <!--                    ? `${$t('The')} ${$t('Last Name')} ${$t('field is required')}`-->
  <!--                    : ''-->
  <!--                }}-->
  <!--              </small>-->
  <!--            </validation-provider>-->
  <!--          </b-form-group>-->
  <!--        </validation-observer>-->
  <!--        <b-button class="d-flex align-items-center rounded-nrm" variant="primary" @click="validationFormInfo">-->
  <!--          {{ $t('Save') }}-->
  <!--        </b-button>-->
  <!--      </b-card>-->
  <!--    </b-col>-->
  <!--  </b-row>-->
</template>

<script>
import {BButton, BCard, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BRow, BTab, BTabs} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import vSelect from 'vue-select'
import {email, required} from '@core/utils/validations/validations'
import codeIcon from 'vue-feather-icons/icons/CodeIcon'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
  },
  data() {
    return {
      tabActive: 0,
      selectedCountry: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      codeIcon,
      countryName: [
        {value: 'select_value', text: 'Select Value'},
        {value: 'Russia', text: 'Russia'},
        {value: 'Canada', text: 'Canada'},
        {value: 'China', text: 'China'},
        {value: 'United States', text: 'United States'},
        {value: 'Brazil', text: 'Brazil'},
        {value: 'Australia', text: 'Australia'},
        {value: 'India', text: 'India'},
      ],
      languageName: [
        {value: 'nothing_selected', text: 'Nothing Selected'},
        {value: 'English', text: 'English'},
        {value: 'Chinese', text: 'Mandarin Chinese'},
        {value: 'Hindi', text: 'Hindi'},
        {value: 'Spanish', text: 'Spanish'},
        {value: 'Arabic', text: 'Arabic'},
        {value: 'Malay', text: 'Malay'},
        {value: 'Russian', text: 'Russian'},
      ],
    }
  },
  methods: {
    formSubmitted() {
      // axios.patch(process.env.VUE_APP_API_URL + 'auth/user/me',
      //     {
      //         first_name: this.first_name,
      //         last_name: this.last_name,
      //         email: this.$store.state.osBoard.user.email,
      //     },
      //     {
      //         headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
      //     }
      // )
      //     .then((response) => {
      //         this.$toast({
      //             component: ToastificationContent,
      //             props: {
      //                 title: 'Form Submitted',
      //                 icon: 'EditIcon',
      //                 variant: 'success',
      //             },
      //         })
      //         this.$router.push('/projects')
      //     })
      //     .catch((error) => {})
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            this.tabActive++
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            this.tabActive++
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            this.formSubmitted()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
